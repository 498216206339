<template>
  <div class="endTool">
    <div>
      <div class="icon phone">{{ cut[locale].end_phone }}</div>
      <div class="text">{{ infoList.sales_service_phone }}</div>
    </div>
    <div>
      <div class="icon Address">{{ cut[locale].end_address }}</div>
      <div class="Address-text"><span>{{ infoList.addree }}</span></div>
    </div>
    <div>
      <div class="icon Email">{{ cut[locale].end_email }}</div>
      <div class="text">{{ infoList.sales_service_service }}</div>
    </div>
  </div>
  <div class="logo"></div>
  <div class="menu">
      <div class="menu-item" v-for="(item, i) in menus[locale]" :key="i" >
        <span @click="skip(item.url);">{{ item.name }}</span>
      </div>
  </div>
  <div class="Copyright"><span v-if="locale==='cn'">版权所有</span><span v-if="locale!=='cn'">All Rights Reserved</span>&nbsp;©&nbsp;{{ infoList.title }}&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/">苏ICP备2022045972号</a></div>
</template>

<script setup>
import {ref} from "vue";
import axios from "axios";
import {apiHost, language, menus,cut} from "@/config";
import router from "@/router";
let locale = localStorage.getItem('locale') !== '' ? localStorage.getItem('locale') : 'cn'

let infoList = ref('')
function skip(url) {
  router.push({name: url})
}
function getInfoList() {
  if (infoList.value) return
  axios({
    method: 'get',
    url: apiHost + '/api/infoList' + language[locale],
  }).then(res => {
    infoList.value =res.data.info.list[0]
  })
}
getInfoList()
</script>

<style scoped>
a:hover, a:visited, a:link, a:active {
  color: #fff;
}
a{text-decoration: none;color:#fff;}
.Copyright{
  height: 80px;
  background: #333333;
  opacity: 1;
  text-align: center;
  line-height: 80px;
  color: #FFFFFF;
  overflow: hidden;
  font-size: 2vw;
  max-font-size: 18px;
}
.logo{
  height: 142px;
  background: url("../assets/logo.svg") no-repeat center / 213px;
}
.phone{
  background: url("../assets/end/1.svg") no-repeat center / 3.5vw;
}
.Address{
  background: url("../assets/end/2.svg") no-repeat center / 3.5vw;
}
.Email{
  background: url("../assets/end/3.svg") no-repeat center / 3.5vw;
}
.endTool{
  width: 100%;
  background: #027EBE;
  opacity: 1;
  color: #FFFFFF;
}

@media screen and (min-width: 1200px) {
  .icon{
    text-align: center;
    height: 6vw;
    padding-top: 5vw;
    margin-bottom: 3vw;
  }

  .Copyright{
    font-size: 21px;
  }
  .menu-item{
    height: 142px;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #707070;
    line-height: 142px;
    padding: 0 30px;
    border-top: 1px solid gray;
  }
  .menu{
    height: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .endTool{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21vw;
  }
  .endTool>div:nth-child(2){
    margin: 0 5vw;
  }
}

@media screen and (max-width: 1200px) {

  .Copyright{
    font-size: 2vw;
  }
  .endTool{
    padding: 10px;
  }
  .endTool>div {
    height: 50px;
    display: flex;
    justify-content: left;
    margin: 10px 0;
  }
  .text{
    padding-top: 14px;
    overflow: hidden;
  }
  .Address-text{
    position: relative;
    width: calc(100% - 120px);
  }
  .Address-text span{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 20px;
  }
  .icon{
    width: 120px;
    height: 100%;
    background-size: 46px;
    background-position: left center;
    padding: 13px 0 0 55px;
  }
  .menu,.mobile{
    display: none;
  }
}
</style>
