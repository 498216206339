<template>
  <div class="heading" id="heading" @mouseleave="index=-1;MenuStyle=false" @mousemove="MenuStyle=true" :class="isMenu?'heading-mobile':''"
       :style="{backgroundColor:MenuStyle?'#fff':''}">
    <div class="logo" @click="skip('home') ">
      <img :src="url1" v-show="MenuStyle"/>
      <img :src="url2" v-show="!MenuStyle"/>
    </div>
    <div class="more" @click="isMenu=!isMenu;index=-1"></div>
    <ul class="menu-list" v-if="menus" v-show="isMenu" :class="isMenu?'menuShow':''">
      <li class="menu-item" v-for="(item, i) in menus[locale]" :key="i" @mouseenter="index=i"
          :style="{color:MenuStyle?'#000':'#fff'}">
        <span  @click="skip(item.url);">{{ item.name }}</span>
        <div class="menu-son" v-show="index===i">
          <div class="menu-son-item" :class="'menu-son-item' + i" v-for="(son,i) in item.son" @click="skip(son.url);">
            {{ son.name }}
          </div>
        </div>
      </li>
      <li class="menu-item" :style="{color:MenuStyle?'#000':''}">
        <span style="display: unset" @click="setLocale('en')">EN/</span>

        <span @click="setLocale('cn')" style="display: unset">CN</span></li>
    </ul>
  </div>
  <el-carousel class="carousel-el"  :autoplay="false" v-if="data">
    <el-carousel-item v-for="item in data.images" v-if="data.video===''">
      <div class="carousel" :style="{backgroundImage: 'url(' + item + ')'}">
        <div class="title">{{ data.title }}</div>
        <div class="read-more" @click="readMore">{{ cut[locale].head_button }}</div>
      </div>
    </el-carousel-item>
    <el-carousel-item v-if="data.video!==''">
      <div class="title">{{ data.title }}</div>
      <video :src="data.video" muted autoplay loop playsInline webkit-playsinline :poster="data.images[0]">
      </video>
    </el-carousel-item>
  </el-carousel>
  <div id="carousel-el"></div>
</template>

<script setup>
import "swiper/css/effect-cards";
import {apiHost, language, menus, cut} from "@/config";
import {onMounted, onUnmounted, ref, watch} from "vue";
import axios from "axios";
import router from "@/router";
import url1 from "@/assets/logo.svg";
import url2 from "@/assets/logo2.svg";

if (!localStorage.getItem('locale')) {
  localStorage.setItem('locale', 'cn')
  location.reload()
}

let locale = localStorage.getItem('locale') !== '' ? localStorage.getItem('locale') : 'cn'
let index = ref(-1)
let data = ref('')
let isMenu = ref(false)
let scrollH = ref(0)
let MenuStyle = ref(false)
let w = ref(0)

function readMore() {

  let el = document.getElementById("carousel-el")
  el.scrollIntoView
  ({
    top: el.scrollHeight,
    behavior: "smooth"
  });
}

function sel(i) {
  data.value = ''
  setTimeout(() => {
    axios({
      method: 'get',
      url: apiHost + '/api/bannerList' + language[locale] + '?type=' + i,
    }).then(res => {
      data.value = res.data.info.list[0]
    })
  })
}

function setLocale(n) {
  localStorage.setItem('locale', n)
  location.reload()
}

function skip(url) {
  if (w.value <= 1300) {
    isMenu.value = false
  }
  router.push({name: url})
}

watch(() => {
  let name = router.currentRoute.value.name
  if (name === 'home') sel(1)
  if (name === 'about') sel(2)
  if (name === 'news') sel(3)
  // if (name === 'newsDetail') sel(3)
  if (name === 'product') sel(4)
  if (name === 'industry') sel(5)
  if (name === 'technical') sel(6)
  if (name === 'sales') sel(7)
  if (name === 'salesService') sel(9)
  if (name === 'afterSalesService') sel(10)
  if (name === 'recruitment') sel(11)
  else {
    data.value = ''
  }
  return router.currentRoute.value
}, (value) => {

})

//页面加载
onMounted(() => {
  // window.addEventListener("scroll", scrollFunc);
  window.addEventListener('resize', getWidth);

});
//页面卸载
onUnmounted(() => {
  // window.removeEventListener("scroll", scrollFunc);
  window.removeEventListener('resize', getWidth);
});
const getWidth = () => {
  w.value = document.documentElement.clientWidth;

  if (w.value > 1300) {
    isMenu.value = true
    MenuStyle.value = false;
  } else {
    isMenu.value = false
    MenuStyle.value = true;
  }
}
getWidth()
const scrollFunc = (e) => {
  // let scrollTop = document.documentElement.scrollTop;
  let documentClientHeight = document.documentElement.clientHeight || window.innerHeight
  let htmlElementClientTop = document.getElementById('carousel-el').getBoundingClientRect().top;
  let value = documentClientHeight - htmlElementClientTop
  if (value >= 30) {
    // TODO 执行你要做的操作
  }
}
</script>
<style>
.el-carousel__indicators,.el-carousel__arrow{
  display: none !important;
}
</style>
<style scoped>
.menu-son-item0 {
  animation: flip-in-horizontal 0.6s ease 0.1s 1 normal backwards;
}

.menu-son-item1 {
  animation: flip-in-horizontal 0.6s ease 0.2s 1 normal backwards;
}

.menu-son-item2 {
  animation: flip-in-horizontal 0.6s ease 0.3s 1 normal backwards;
}

.menu-son-item3 {
  animation: flip-in-horizontal 0.6s ease 0.4s 1 normal backwards;
}

.menu-son-item4 {
  animation: flip-in-horizontal 0.6s ease 0.5s 1 normal backwards;
}

@keyframes flip-in-horizontal {
  0% {
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

#carousel {
  height: 0;
}

.read-more {
  position: absolute;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  z-index: 2;
  height: 50px;
  color: #ffffff;
  width: 162px;
  background: #28A7E1;
  border-radius: 4px 4px 4px 4px;
  line-height: 50px;
  opacity: 1;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1300px) {

  .more {
    display: none;
  }

  .read-more {
    margin-top: 71px;
  }

  .carousel-el{
    height: 100vh;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .menu-son {
    transition: all 0.5s ease;
  }

  .menu-son-item {
    background: #FFFFFF;
    height: 62px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 62px;
    color: #333333;
    transition: 0.5s ease all;
    cursor: pointer;
  }

  .menu-son-item:hover {
    background: #28A7E1;
    color: #ffffff;
  }

  .menu-item {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    height: 113px;
    z-index: 1;
    width: 160px;
    text-align: center;
    margin: 0 -2px;
    font-size: 23px;
    font-weight: 400;
    color: #ffffff;
    vertical-align: center;
    line-height: 113px;
  }
  .menu-item::after {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    left: 100%;
    width: 0;
    height: 100%;
    border-bottom: 2px solid #28A7E1;
    transition: 0.2s all linear;
  }

  .menu-item:hover::after {
    width: 100%;
    left: 0;
  }

  .menu-item:hover~.menu-item::after {
    left: 0;
  }

  .menu-item > span {
    display: block;
    cursor: pointer;
    text-shadow:-6px 6px 18px rgba(0,0,0,.35);
  }

  .menu-list {
    display: flex;
    user-select: none;
    height: 113px;
    flex-wrap: nowrap;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    backdrop-filter: blur(3px);
  }

  .heading {
    position: -webkit-sticky;
    position: sticky;
    transition: 0.5s all ease;
    top: 0;
    z-index: 1300;
    background: no-repeat 8.8vw center / 220px;
    height: 113px;
    width: 100%;
    border-bottom: 2px solid #2CB9EF;
    margin-bottom: -113px;
  }
  .heading:hover {
    background: #F1F1F1;
  }
  .title {
    position: absolute;
    text-align: center;
    font-size: 45px;
    font-weight: bold;
    z-index: 2;
    width: 100%;
    height: 71px;
    color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .carousel {
    background-size: cover;
  }
}
.carousel {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
@media screen and (max-width: 1300px) {
  .carousel {
    background-size: 100% 100%;
  }
  .read-more {
    display: none;
  }

  .carousel-el {
    margin-top: 80px;
    height: 60vw;
  }

  .more {
    max-width: 50px;
    position: absolute;
    background: url("../assets/home/more.svg") no-repeat center / 60%;
    width: 10vw;
    height: 100%;
    right: 3vw;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .menu-son {
    background: #FFFFFF;
    transition: all 0.5s ease;
  }

  .menu-son-item {
    height: 80px;
    font-size: 16px;
    text-align: center;
    font-family: Segoe UI-Regular, Segoe UI;
    font-weight: 300;
    line-height: 80px;
    color: #333333;
    transition: 0.5s ease all;
  }

  .menu-son-item:hover {
    background: #28A7E1;
    color: #ffffff;

  }

  .menu-item {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    height: auto;
    z-index: 1;
    text-align: center;
    font-size: 23px;
    font-weight: 300;
    color: #ffffff;
    vertical-align: center;
    line-height: 80px;
    cursor: pointer;
  }

  .menu-list {
    height: 0;
    position: fixed;
    z-index: 2022;
    width: 100%;
    background: #fff;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    animation: 0.5s menu-list both;
  }

  @keyframes menu-list {
    from {
      height: 0
    }
    to {
      height: calc(100vh - 80px);
    }
  }
  .heading {
    position: -webkit-sticky;
    position: fixed;
    transition: 0.5s all ease;
    top: 0;
    left: 0;
    z-index: 1300;
    height: 80px;
    width: 100%;
    border-bottom: 2px solid #2CB9EF;
    margin-bottom: -80px;
  }

  .heading-mobile {
    background-color: #FFFFFF;
  }

  .title {
    position: absolute;
    text-align: center;
    font-size: 6vw;
    font-weight: bold;
    z-index: 2;
    width: 100%;
    height: 6vw;
    color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.logo {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40vw;
  max-width: 250px;
  height: auto;
  z-index: 2;
  transition: all 1s;
}

.logo > img {
  width: 100%;
  height: 100%;
}
</style>
