import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../views/RedirectView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('../views/NewsDetailView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/industry',
    name: 'industry',
    component: () => import('../views/IndustryView.vue')
  },
  {
    path: '/industryDetail',
    name: 'industryDetail',
    component: () => import('../views/IndustryDetailView.vue')
  },
  {
    path: '/technical',
    name: 'technical',
    component: () => import('../views/TechnicalView.vue')
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('../views/SalesView.vue')
  },
  {
    path: '/afterSalesService',
    name: 'afterSalesService',
    component: () => import('../views/afterSalesServiceView.vue')
  },
  {
    path: '/salesService',
    name: 'salesService',
    component: () => import('../views/SalesServiceView.vue')
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    component: () => import('../views/RecruitmentView.vue')
  },
  {
    path: '/recruitmentDeliver',
    name: 'recruitmentDeliver',
    component: () => import('../views/RecruitmentDeliverView.vue')
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import('../views/ProductDetailView.vue')
  },
]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes
})

export default router
