<template>
  <div class="home">
    <div class="par" :class="step1?'logoM':'anime'">
      <div class="par-item">
        <div class="par-list par-list1"></div>
        <div class="par-line"></div>
        <div class="par-list par-list2"></div>
        <div class="par-line"></div>
        <div class="par-list par-list3"></div>
      </div>
    </div>
    <div class="industry-item" :class="step2?'logoM':'anime'">
      <div class="industry-list" v-for="(item , i) in data2" v-if="data2">
        <div class="industry-matter" @mouseenter="selIndustry(i)">
          <div style="  cursor: pointer;" :class="industry===i?'industry-sel':''">{{ item[0].type_name }}</div>
          <div style="  cursor: pointer;" class="industry-button" :class="industry===i?'industry-button-sel':''"></div>
        </div>
        <div class="industry-img" :class="item[0].image?'':'industry-img-empty'"
             :style="{backgroundImage: 'url(' + item[0].image + ')'}">
        </div>
        <div class="industry-more" @click="moreProList(i)">{{ cut[locale].head_button }}</div>
      </div>
      <div class="industry-more-list" v-show="industry!==-1">
        <div v-for="item in data2[industry]" class="industry-more-img" @click="moreList(item.id)"
             :style="{backgroundImage: 'url(' + item.image + ')'}">
          <div class="industry-more-button">{{ item.title }}</div>
        </div>
        <div class="industry-close" @click="industry=-1"></div>
      </div>
    </div>
    <div class="industry">
      <div class="divider" :class="step3?'logoM':'anime'">
        <div>{{ cut[locale].industry_name }}</div>
      </div>
      <div class="subtitle" :class="step3?'logoM':'anime'">{{ cut[locale].industry_title }}</div>
      <swiper :slidesPerView="4" class="subtitleSwiper" :class="step4?'logoM':'anime'">
        <swiper-slide v-for="item in trade">
          <div class="subtitle-bg" :style="{backgroundImage: 'url(' + item.image + ')'}"
               @click="industryDetail(item.id)">
            <div class="subtitle-title">{{ item.title }}</div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="divider" :class="step5?'logoM':'anime'">
        <div>{{ cut[locale].culture }}</div>
      </div>
      <div class="subtitle" :class="step5?'logoM':'anime'">{{ cut[locale].culture_title }}</div>
      <swiper :class="step6?'logoM':'anime'"
              class="newses"
              :slides-per-view="1"
              :space-between="50"
              :effect="'cards'"
              :grabCursor="true"
              :modules="modules"
      >
        <swiper-slide v-for="item in news">
          <div class="news-bg" @click="moreNews(item.id)">
            <div class="news-img" :style="{backgroundImage: 'url(' + item.image + ')'}">

            </div>
            <div class="news-text">
              <div class="news-title">{{ item.title }}</div>
              <div class="news-content">{{ item.content }}</div>
              <div class="news-time">{{ item.createtime.substring(0, 10) }}</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

  </div>
</template>

<script setup>
import axios from "axios";
import {apiHost, language, cut} from "@/config.js";
import {onMounted, onUnmounted, ref} from "vue";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {EffectCards} from "swiper";
import "swiper/css/effect-cards";
import {useRouter} from "vue-router";

let router = useRouter();

function moreList(id) {
  if (id === 32) return
  router.push({
    name: "productDetail",
    query: {
      id: id
    }
  })
}

function moreNews(id) {
  router.push({
    name: "newsDetail",
    query: {
      id: id
    }
  })
}

function industryDetail(id) {
  router.push({
    name: "industryDetail",
    query: {
      id: id
    }
  })
}

let locale = localStorage.getItem('locale') !== '' ? localStorage.getItem('locale') : 'cn'
let data = ref('')
let data2 = ref('')
let news = ref('')
let trade = ref('')
let isSelIndustry = ref(true)
let industry = ref(-1)
let modules = [EffectCards]
let step1 = ref(false)
let step2 = ref(false)
let step3 = ref(false)
let step4 = ref(false)
let step5 = ref(false)
let step6 = ref(false)
onMounted(() => {
  window.addEventListener("scroll", scrollFunc);
});
onUnmounted(() => {
  window.removeEventListener("scroll", scrollFunc);
});

const scrollFunc = (e) => {
  let scrollTop = document.documentElement.scrollTop;
  let w = document.documentElement.clientWidth;
  if (w < 1300) {
    window.removeEventListener("scroll", scrollFunc);
  }
  if (scrollTop < 200) {
    step1.value = false
    step2.value = false
    step3.value = false
    step4.value = false
    step5.value = false
    step6.value = false
  }
  if (scrollTop >= 200) step1.value = true
  if (scrollTop >= 560) step2.value = true
  if (scrollTop >= 1165) step3.value = true
  if (scrollTop >= 1500) step4.value = true
  if (scrollTop >= 2233) step5.value = true
  if (scrollTop >= 2500) step6.value = true
}

function moreProList(t) {
  if (t === '更多方案') return
  router.push({
    name: "product",
    query: {
      type: t
    }
  })
}

function selIndustry(i) {
  if (isSelIndustry.value) {
    isSelIndustry.value = false
    if (industry.value === i) {
      industry.value = -1
    } else {
      industry.value = i
    }
    setTimeout(() => {
      isSelIndustry.value = true
    }, 300)
  }
}

function getNews() {
  if (news.value) return
  axios({
    method: 'get',
    url: apiHost + '/api/listNews' + language[locale],
  }).then(res => {
    news.value = res.data.info.list
  })
}

function getTrade() {
  if (trade.value) return
  axios({
    method: 'get',
    url: apiHost + '/api/solistNews' + language[locale],
  }).then(res => {
    trade.value = res.data.info.list
  })
}

getTrade()
getNews()


function culture() {
  if (data2.value) return
  axios({
    method: 'get',
    url: apiHost + '/api/productList' + language[locale],
  }).then(res => {
    data2.value = getGroup(res.data.info.list, 'type_name')
  })
}

function getGroup(data, key) {
  let groups = {};
  data.forEach(c => {
    let value = c[key];
    groups[value] = groups[value] || [];
    groups[value].push(c);
  });
  return groups;
}

culture()
</script>

<style scoped>
@media screen and (max-width: 1200px) {


  .subtitle-title {
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 4vw;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 34px;
    text-align: center;
    z-index: 2;
  }

  .subtitle-bg {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    background: no-repeat center / cover;
  }

  .subtitle-bg:hover:after {
    background: rgba(51, 51, 51, 0.5);
  }

  .subtitle-bg:after {
    cursor: pointer;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(51, 51, 51, 0.3);
    transition: all 0.5s ease;
  }

  .subtitleSwiper {
    height: 50vw;
  }

  .news-content {
    font-size: 2.0vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 1.5;
    overflow: hidden;
    height: 54%;
    text-align: left;
  }

  .news-title {
    font-size: 3vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #28A7E1;
    line-height: 1.5;
    margin-bottom: 2vw;
    text-align: left;
    height: 26%;
    overflow: hidden;
  }

  .news-time {
    position: absolute;
    bottom: 1vw;
    right: 5vw;
    height: 3vw;
    font-size: 2vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #28A7E1;
    line-height: 3vw;
  }

  .news-img {
    float: left;
    width: 40%;
    height: 100%;
    transition: all 0.5s ease;
    background: no-repeat center / cover;
  }

  .news-text {
    float: left;
    width: 60%;
    padding: 2%;
    height: 100%;
    position: relative;
  }

  .newses {
    width: 100vw;
    height: 60vw;
  }

  .news-bg {
    width: 90%;
    height: 95%;
    margin: auto;
    background: #E9E9E9 no-repeat center left / 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .industry-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: url("../assets/home/close.png") no-repeat center / cover;
    width: 22px;
    height: 23px;
    cursor: pointer;
  }

  .industry-more-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
    background: #027EBE;
    border-radius: 18px;
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 24px;
    padding: 10px;
    text-align: center;
  }

  .industry-more-list {
    display: none;
    padding: 50px;
    position: absolute;
    z-index: 1;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 95px;
    background: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation: shutter-in-top 0.6s linear 0s 1 normal none;
  }

  @keyframes shutter-in-top {
    0% {
      transform: rotateX(-100deg);
      transform-origin: top;
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg);
      transform-origin: top;
      opacity: 1;
    }
  }

  .industry-list {
    height: inherit;
    position: relative;
    width: 90%;
    margin: 5vw auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px 0px rgba(45, 92, 163, 0.1700);
    border-radius: 10px;
    padding: 5%;
  }

  .industry-more {
    width: 124px;
    height: 37px;
    background: #027EBE;
    border-radius: 6px;
    margin: 0 auto;
    font-size: 17px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 37px;
    text-align: center;
    cursor: pointer;
  }

  .industry-img-empty {
    background-image: url("../assets/home/empty.png");
  }

  .industry-img {
    height: 40vw;
    background: no-repeat center / auto 110%;
    transition: all 0.5s ease;
    margin: 5px auto;
  }

  .industry-more-img {
    height: 50vw;
    width: 100%;
    margin: 0 auto;
    float: left;
    position: relative;
    transition: all 0.5s ease;
    background: no-repeat center top / 75%;
  }

  .industry-img:hover, .industry-more-img:hover {
  }

  .industry-sub {
    position: relative;
    top: 50px;
    width: 100%;
    margin: 0 auto;
    height: 220px;
  }

  .industry-button {
    display: none;
    margin-left: auto;
    background: url("../assets/home/arrow.png") no-repeat center / 20px;
    width: 40px;
    height: 30px;
    transition: all 0.5s ease;
  }

  .industry-sel {
    font-weight: bold;
    color: #027EBE;
  }

  .industry-button-sel {
    transform: rotate(180deg);
  }


  .industry-matter {
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0 auto;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #2CB9EF;
    border-bottom: 2px solid #2CB9EF;

  }

  .industry-item {
    /*margin: 46px auto;*/
    position: relative;
  }

  .subtitle {
    text-align: center;
    height: 4vw;
    font-size: 3vw;
    font-weight: 300;
    color: #999999;
    line-height: 4vw;
    margin-bottom: 5vw;
    margin-top: 2vw;
  }

  .industry {
    width: 100%;
    background: #ffffff;
    overflow: hidden;
  }

  .divider > div {
    text-align: center;
    background: white;
    padding: 0 5px;
    min-width: 50px;
    position: absolute;
    left: 50%;
    transform: translate(-50%) translateY(-50%);
    font-size: 6vw;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #027EBE;
  }

  .divider {
    white-space: nowrap;
    display: block;
    height: 1px;
    width: 70%;
    margin: 8vw auto 8vw auto;
    border-top: 1px solid #2CB9EF;
  }

  .par-line {
    width: 2px;
    height: 80%;
    border-radius: 1px;
    background: #E5E5E5;
  }

  .par-list1 {
    background: url("../assets/home/1.png") no-repeat center;
  }

  .par-list2 {
    background: url("../assets/home/2.png") no-repeat center;
  }

  .par-list3 {
    background: url("../assets/home/3.png") no-repeat center;
  }

  .par-item {
    margin: 0 auto;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }

  .par-list {
    width: 33%;
    height: inherit;
    background-size: 50%;
    transition: 0.5s all ease;
  }


  .par {
    display: none;
    border-radius: 2vw;
    width: 90%;
    height: 30vw;
    background-image: linear-gradient(0deg, #ffffff, #ffffff);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin: 5vw auto;
  }

  .home {
    background: #DCDCDC;
    width: 100%;
    overflow: hidden;
  }
}

@media screen and (min-width: 1201px) {
  .logoM {
    animation: fade-in-bottom 1s ease 0s 1 normal backwards;
  }

  @keyframes fade-in-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .anime {
    opacity: 0;
  }

  .subtitle-title {
    position: absolute;
    bottom: 113px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 31px;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 34px;
    text-align: center;
    z-index: 2;
  }

  .subtitle-bg {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    background: no-repeat center / cover;
  }

  .subtitle-bg:hover:after {
    background: rgba(51, 51, 51, 0.5);
  }

  .subtitle-bg:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(51, 51, 51, 0.3);
    transition: all 0.5s ease;
  }

  .subtitleSwiper {
    height: 781px;
  }

  .news-content {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 20px;
    height: 320px;
    overflow: hidden;
  }

  .news-title {
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #28A7E1;
    line-height: 31px;
    margin-bottom: 20px;
  }

  .news-time {
    position: absolute;
    bottom: 56px;
    right: 74px;
    height: 25px;
    font-size: 19px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #28A7E1;
    line-height: 25px;
  }

  .news-img {
    float: left;
    width: 60%;
    height: 100%;
    transition: all 0.5s ease;
    background: no-repeat center / cover;
  }

  .news-text {
    float: left;
    width: 40%;
    padding: 60px;
    height: 100%;
    position: relative;
  }

  .newses {
    width: 1416px;
    height: 554px;
  }

  .news-bg {
    width: 90%;
    height: 95%;
    margin: auto;
    background: #E9E9E9 no-repeat center left / 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .industry-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: url("../assets/home/close.png") no-repeat center / cover;
    width: 22px;
    height: 23px;
    cursor: pointer;
  }

  .industry-more-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
    background: #027EBE;
    border-radius: 18px;
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 24px;
    padding: 10px;
    text-align: center;
  }

  .industry-more-list {
    padding: 50px;
    position: absolute;
    z-index: 1;
    width: 1488px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 80px;
    height: 380px;
    background: rgba(248, 247, 247, 0.9);
    backdrop-filter: saturate(150%) blur(8px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation: shutter-in-top 0.6s linear 0s 1 normal none;
  }

  @keyframes shutter-in-top {
    0% {
      transform: rotateX(-100deg);
      transform-origin: top;
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg);
      transform-origin: top;
      opacity: 1;
    }
  }

  .industry-list {
    height: inherit;
    position: relative;

  }

  .industry-more {
    width: 124px;
    height: 37px;
    background: #027EBE;
    border-radius: 6px;
    margin: 0 auto;
    font-size: 17px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 37px;
    text-align: center;
    cursor: pointer;
  }

  .industry-img-empty {
    background-image: url("../assets/home/empty.png");
  }

  .industry-img {
    height: 326px;
    margin: 0 auto;
    background: no-repeat center / 90%;
    transition: all 0.5s ease;
  }

  .industry-more-img {
    height: 100%;
    width: 230px;
    margin: 0 auto;
    float: left;
    position: relative;
    transition: all 0.5s ease;
    background: no-repeat center top / auto 75%;
  }

  .industry-more-img:hover {
    background-size: auto 85%;
  }

  .industry-img:hover {
    background-size: 100%;
  }

  .industry-sub {
    position: relative;
    top: 50px;
    width: 1200px;
    margin: 0 auto;
    height: 220px;
  }

  .industry-button {
    margin-left: auto;
    background: url("../assets/home/arrow.png") no-repeat center / 20px;
    width: 40px;
    height: 30px;
    transition: all 0.5s ease;
  }

  .industry-sel {
    font-weight: bold;
    color: #027EBE;
  }

  .industry-button-sel {
    transform: rotate(180deg);
  }


  .industry-matter {
    cursor: pointer;
    width: 250px;
    height: 60px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0 10px;
    padding: 10px 0;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    border-bottom: 2px solid #2CB9EF;

  }

  .industry-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 46px;
    position: relative;
  }

  .subtitle {
    text-align: center;
    height: 42px;
    font-size: 31px;
    font-weight: 300;
    color: #999999;
    line-height: 42px;
    margin-bottom: 78px;
    margin-top: 40px;
  }

  .industry {
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    padding-top: 95px;
  }

  .divider > div {
    text-align: center;
    background: white;
    padding: 0 5px;
    min-width: 50px;
    position: absolute;
    left: 50%;
    transform: translate(-50%) translateY(-50%);
    font-size: 41px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #027EBE;
  }

  .divider {
    white-space: nowrap;
    display: block;
    height: 1px;
    width: 70%;
    margin: 100px auto 24px auto;
    border-top: 1px solid #2CB9EF;
  }

  .par-line {
    width: 2px;
    height: 99px;
    border-radius: 1px;
    background: #E5E5E5;
  }

  .par-list1 {
    background: url("../assets/home/1.png") no-repeat center;
  }

  .par-list2 {
    background: url("../assets/home/2.png") no-repeat center;
  }

  .par-list3 {
    background: url("../assets/home/3.png") no-repeat center;
  }

  .par-item {
    margin: 0 auto;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 1238px;
  }

  .par-list {
    width: 441px;
    height: inherit;
    background-size: 146px;
    transition: 0.5s all ease;
  }


  .par {
    width: 100%;
    height: 268px;
    background-image: linear-gradient(0deg, #ffffff, #ffffff);
    background-size: 1238px 268px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 117px;
    top: 60px;
    position: relative;
  }

  .home {
    background: #DCDCDC;
    width: 100%;
  }
}
</style>
