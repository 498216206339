const env = process.env.NODE_ENV;
const apiHost = {
    development: 'http://www.gnordrive.com:9001',
    production: 'https://www.gnordrive.com/api'
    // production: 'http:///www.gnordrive.com:9001'
}
menus = {
    en: [
        {
            name: 'Home',
            url: 'home',
            son: []
        },
        {
            name: 'Culture',
            url: 'about',
            son: [
                {
                    name: 'About us',
                    url: 'about',
                },
                {
                    name: 'News',
                    url: 'news',
                }
            ]
        },
        {
            name: 'Products',
            url: 'product',
            son: []
        },
        {
            name: 'Application',
            url: 'industry',
            son: []
        },
        {
            name: 'Technology',
            url: 'technical',
            son: [
                // {
                //     name: 'Technical Support',
                //     url: 'technical',
                // },
                // {
                //     name: 'After Sales Support',
                //     url: 'sales',
                // },
            ]
        },
        {
            name: 'Contact Us',
            url: 'salesService',
            son: [
                {
                    name: 'After-sales service',
                    url: 'afterSalesService',
                },
                {
                    name: 'Sales service',
                    url: 'salesService',
                },
                {
                    name: 'Talent recruitment',
                    url: 'recruitment',
                },
            ]
        },
    ],
    cn: [
        {
            name: '首页',
            url: 'home',
            son: []
        },
        {
            name: '文化',
            url: 'about',
            son: [
                {
                    name: '关于我们',
                    url: 'about',
                },
                {
                    name: '新闻',
                    url: 'news',
                }
            ]
        },
        {
            name: '产品',
            url: 'product',
            son: []
        },
        {
            name: '行业',
            url: 'industry',
            son: []
        },
        {
            name: '技术',
            url: 'technical',
            son: [
                // {
                //     name: '技术支持',
                //     url: 'technical',
                // },
                // {
                //     name: '售后支持',
                //     url: 'sales',
                // },
            ]
        },
        {
            name: '联系我们',
            url: 'afterSalesService',
            son: [
                {
                    name: '售后服务',
                    url: 'afterSalesService',

                },
                {
                    name: '销售服务',
                    url: 'salesService',
                },
                {
                    name: '人才招聘',
                    url: 'recruitment',
                },
            ]
        },
    ],
}

cut = {
    en:
        {
            head_button: 'Read More',
            industry_name: 'Industry',
            industry_title: 'Industries we cater to',
            culture: 'Culture',
            culture_title: 'Company news',
            return: 'Return',
            manual: 'Manual',
            brochure: 'Brochure',
            sales_title: 'After Sales Support',
            information: 'Personal information',
            information_table: {name:'Name',email:'Email',telephone:'Telephone',code:'Terification code',delivery:'Delivery',upload:'Upload resume',tip:'(Acceptable resume attachment file formats are word, pdf, jpg)'},
            end_phone: 'Phone',
            end_address: 'Address',
            end_email: 'Email',
            after:{Name:'Name',Email:'Email',Subject:'Subject',Message:'Message',SEND:'SEND',title:'Contact  Information',address:'Address',tel:'Tel',join:'Join Us',sales:'sales service',after:'after service'},
            after_tile:'After-Sales Service',
            after_text:'',
            sales_text:'',
            sales_t:'Sales Service',
            recruitment:{resume:'Resume template',description:'job description',requirement:'job requirement',email:'talent recruitment email',phone:'talent recruitment phone',more:'View information',name:'Position Name',department:'Recruiting Department',time:'Release Time',recruits:'Number of recruits',information:'Information',delivery:'Delivery'}
        }
    ,
    cn:
        {
            head_button: '查看更多',
            industry_name: '行业应用',
            industry_title: '我们所服务的行业',
            culture: '文化',
            culture_title: '公司新闻',
            return: '返回',
            manual: '使用手册',
            brochure: '产品样本',
            sales_title: '售后支持',
            information: '加入我们',
            information_table: {name:'姓名',email:'邮件地址',telephone:'联系电话',code:'验证码',delivery:'投递',upload:'上传简历',tip:'(可接受的简历附件文件格式如下：word, pdf, jpg)'},
            end_phone: '电话',
            end_address: '地址',
            end_email: '邮箱',
            after:{Name:'姓名',Email:'邮箱',Subject:'主题',Message:'消息',SEND:'发送',title:'联系信息',address:'地址',tel:'联系电话 ',join:'人才招聘',sales:'销售服务',after:'售后服务'},
            after_tile:'售后服务',
            after_text:'',
            sales_text:'',
            sales_t:'销售服务',
            recruitment:{resume:'简历模板',description:'工作描述',requirement:'工作要求',email:'邮箱',phone:'电话',more:'详细信息',name:'职位名称',department:'职位部门',time:'发布日期',recruits:'招聘人数',information:'信息',delivery:'投递'}
        }
}
language =
    {
        en: 'En',
        cn: ''
    }

module.exports = {
    apiHost: apiHost[env],
    menus,
    cut,
    language
}
