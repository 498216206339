import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from "@/router";
import 'element-plus/dist/index.css'
import 'swiper/css';
import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-cn'
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})
const app = createApp(App)
app.use(ElementPlus,{locale})
app.use(store).use(router).mount('#app')
